<template>
  <div>
    <b-sidebar @shown="" :width="'33.333333%'" backdrop id="kycForm" header-class="p-0" footer-class="p-0" shadow right no-close-on-backdrop>
    <template #header>
        <div class="w-100">
          <div class="p-3">
            <div class="title">KYC Form &nbsp; <span>Receipt and EOI will be generated after this step.</span></div>
          <div class="d-flex justify-content-between mt-4">
            <button v-b-toggle.kycForm type="button" class="prevButton"><img src="../assets/images/back-arrow.svg" alt=""> Back</button>
            <!-- <button class="btn btn-primary" type="button">Block</button> -->
          </div>
        </div>
        <div class="unit-details-wrapper px-3">
      <div class="variant-detail text-center" v-if="!selectedUnit.id">
          <span class="unit-value">Select a Unit</span>
        </div>
      <div class="unit-details mb-3 row" v-if="selectedUnit && selectedUnit.id">
        <div class="variant-detail col">
          <span class="unit-title">Flat No </span>
          <span class="unit-value">{{ selectedUnit.name }}</span>
        </div>
        <div class="variant-detail col">
          <span class="unit-title">RERA</span>
          <span class="unit-value">{{ selectedUnit.carpet_area }} Sq.ft</span>
        </div>
        <div class="variant-detail col">
          <span class="unit-title">MOFA</span>
          <span class="unit-value">{{ selectedUnit.sale_area }} Sq.ft</span>
        </div>
      </div>
    </div>
        <hr class="mb-0">
        </div>
    </template>
    <div class="px-3 py-4">
      <b-form id="kycFormDetails">
        <div class="row">
          <div class="col-12 form-input-group">
            <label for="firstName">CLIENT NAME <span class="text-danger">*</span></label>
            <b-form-input id="firstName" v-model="firstName" placeholder="First Name" size="lg" required :state="firstNameCheck" debounce="1000"></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">Please enter a vaild name</b-form-invalid-feedback>
          </div>
          <div class="col-12 form-input-group">
            <label for="phone">CONTACT NO <span class="text-danger">*</span></label>
            <b-form-input id="phone" v-model="phone" placeholder="Phone Number" size="lg" required :state="phoneCheck" type="number" debounce="1000"></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">Please enter a vaild phone number</b-form-invalid-feedback>
          </div>
          <div class="col-12 form-input-group">
            <label for="email">EMAIL</label>
            <b-form-input id="email" v-model="email" placeholder="Email Address" size="lg" :state="emailCheck" debounce="1000"></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">Please enter a vaild email address</b-form-invalid-feedback>
          </div>
          <div class="col-12 form-input-group">
            <label for="pan">APPLICANT PAN NUMBER</label>
            <b-form-input id="pan" v-model="pan" placeholder="PAN Number" size="lg" :state="pancheck" debounce="1000"></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">Please enter a vaild PAN number</b-form-invalid-feedback>
          </div>
          <div class="col-12 form-input-group">
            <label for="adhaar">APPLICANT ADHAAR NUMBER</label>
            <b-form-input id="adhaar" v-model="adhaar" placeholder="Adhaar Number" size="lg" :state="adhaarcheck" debounce="1000"></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">Please enter a vaild ADHAAR number</b-form-invalid-feedback>
          </div>
          <div class="col-12 form-input-group">
            <label for="adhaar">CP NAME</label>
            <b-form-input id="broker_name" v-model="broker_name" placeholder="CP Name" :state="cpNameCheck" size="lg"></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">Please enter a valid name</b-form-invalid-feedback>
          </div>
          <div class="col-12 form-input-group">
            <label for="adhaar">CP CONTACT</label>
            <b-form-input id="broker_contact" v-model="broker_contact" placeholder="CP Contact" type="number" :state="cpPhoneCheck" size="lg"></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">Please enter a valid number</b-form-invalid-feedback>
          </div>
        </div>
      </b-form>
    </div>
    <template #footer>
      <hr class="m-0">
       <div class="d-flex align-items-center px-3 py-3">
        <button @click="handleBooking" class="btn btn-primary px-5 py-3 mx-auto mx-md-0 ml-md-auto" type="button">Book Now</button>
       </div>
      </template>
  </b-sidebar>
</div>
</template>

<script>

  export default {
    data() {
      return {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        config: '',
        preferredUnit: '',
        pan: '',
        adhaar: '',
        broker_name: '',
        broker_contact: '',
        users: [],
        bookedBy: null
      }
    },
    methods: {
      handleForm(){
        if(this.firstNameCheck && this.phoneCheck && this.bookedBy){
          this.$bvModal.show('otpForm')
        }else{
          this.$bvToast.toast('Please fill in the mandatory fields', {
            title: 'Details Incomplete',
            variant: 'danger',
            solid: true
          })
        }
      },
      handleBooking(event) {
        if(this.firstNameCheck && this.phoneCheck && this.selectedUser){
          let vm = this;
          this.$axios.post('inventories/block',{
            "id": vm.selectedUnit.id,
            "user_id": vm.selectedUser.id,
            "broker_name": vm.broker_name,
            "broker_contact": vm.broker_contact,
            "client": {
                "name":  `${vm.firstName} ${vm.lastName}`,
                "email": vm.email || '',
                "contact": vm.phone,
                "password": vm.pan || 'password',
                "pan_card": vm.pan,
                "aadhar": vm.adhaar
            }
          }).then(res => {
            if(res.data.message === "Success"){
              vm.$router.push({ name: 'Confirmation'});
              document.querySelector('body').classList.remove('sidebar-open');
            }
          }).catch(res => {
            this.$bvToast.toast('Unable to process the request at the moment. Please try again after sometime', {
              title: 'Oops',
              variant: 'danger',
              solid: true
            })
            this
          })
        }else{
          this.$bvToast.toast('Please fill in the mandatory fields', {
            title: 'Details Incomplete',
            variant: 'danger',
            solid: true
          })
        }
        
      },
      getUsers(){
        this.$axios.get('inventories/fetch_users').then(res => {
          this.users = res.data.users
        })
      }
    },
    computed: {
      selectedUnit() {
        return this.$store.getters.selectedUnit;
      },
      selectedTypology() {
        return this.$store.getters.selectedTypology;
      },
      selectedFloor() {
        return this.$store.getters.selectedFloor;
      },
      selectedUser() {
        return this.$store.getters.user;
      },
      firstNameCheck(){
        return this.firstName?.length > 2 ? true : this.firstName == '' ? null : false
      },
      lastNameCheck(){
        return this.lastName?.length > 2 ? true : this.lastName == '' ? null : false
      },
      phoneCheck(){
        return this.phone?.length === 10 ? true : this.phone == '' ? null : false
      },
      emailCheck(){
        if(this.email == ''){ return null}
        else{
          let emailRegEx = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
          return emailRegEx.test(this.email)
        }
      },
      pancheck(){
        if(this.pan == ''){ return null}
        else{
          let panRegEx = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
          return panRegEx.test(this.pan)
        }
      },
      adhaarcheck(){
          if(this.adhaar == ''){ return null}
        else{
          let adhaarRegEx = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/);
          return adhaarRegEx.test(this.adhaar)
        }
      },
      cpPhoneCheck(){
        return this.broker_contact?.length === 10 ? true : this.broker_contact == '' ? null : false
      },
      cpNameCheck(){
        return this.broker_name?.length > 2 ? true : this.broker_name == '' ? null : false
      },
    },
    mounted(){
      // this.getUsers();
      this.config = this.selectedUnit.configuration;
      this.preferredUnit = this.selectedUnit.name;
      this.$root.$on('bv::collapse::state', (kycForm, isShown) => {
        if(isShown){
          document.querySelector('body').classList.add('sidebar-open')
        }else{
          document.querySelector('body').classList.remove('sidebar-open')
        }
      })
    }
  }
</script>

<style scoped>

.unit-details .unit-title{
    display: block;
    color: #00000080;
    margin-bottom: 6px;
}

.unit-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
}

.unit-details .unit-value{
    font-weight: 600;
    font-size: 18px;
    display: block;
    color: #000000;
}

.btn-primary{
  line-height: 1;
  width: 100%;
  background: #1EB4D2 !important;
  box-shadow: none !important;
  border: transparent !important;
}

  .title{
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    color: #000000;
    opacity: 1;
  }

  .title span{
    font-size: 12px;
    font-weight: 400;
    opacity: 0.75;
  }
  
  .prevButton {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 13px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: 0.3s;
}

.prevButton:hover{
  gap: 10px;
}

.form-input-group{
  position: relative;
  margin-bottom: 40px;
  font-size: 12px;
}

.form-input-group label{
  position: absolute;
  font-size: 10px;
  background-color: #fff;
  line-height: 1;
  top: 0;
  left: 28px;
  transform: translateY(-50%);
  z-index: 2;
}

.form-input-group input{
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding: 16px 18px;
  height: auto;
  box-shadow: none !important;
}

.was-validated .form-control:valid, .form-control.is-valid{
  background-image: none;
}

.invalid-feedback{
  position: absolute;
}

</style>