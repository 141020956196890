<template>
  <div class="container-fluid bg-white">
  <div class="confirmation-page pb-4">
      <img src="@/assets/images/pb-logo.png" width="180" alt="" class="img-fluid pt-5">
      <img src="@/assets/images/confirm.gif" width="148" alt="" class="img-fluid py-4 mb-3">
      <p class="f-14 fw-600 px-3">Congratulations! Your booking is now confirmed in Shubham Artesia.Thank you for having faith in Shubham Group.</p>
      

      <RouterLink class="text-primary" to="/">Go back to Home</RouterLink>
      <!-- <div class="powered-by powered-by-mob">Powered by <span class="powered-by-ppistol ml-1"> Enrichr</span></div> -->
    </div>
    
  
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted(){
    this.$store.dispatch('setLoading',false);
  },
   
  computed: {
    selectedUnit() {
      return this.$store.getters.selectedUnit;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    selectedWing(){
      return this.$store.getters.selectedWing;
    },
    amount(){
      return this.$store.getters.amount;
    },
    userId(){
      return this.$store.getters.userId
    },
    planId(){
      return this.$store.getters.planId
    },
    totalAmount(){
      return `₹ ${Number(this.amount).toLocaleString('en-IN')}`
    }
  }
}
</script>

<style scoped>
  .confirmation-page{
    min-height:  100dvh;
    min-height:  100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
  }

  .text-primary, .text-primary:hover{
    color: #1EB4D2 !important;
  }

  

  .f-14{
    font-size: 14px;
  } 
  .fw-500{ font-weight: 500;}
  .fw-600{ font-weight: 600;}
</style>