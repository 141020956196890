<template>
  <div class="progress-bar-wrapper" id="nr-progress-bar">
    <div class="custom-progress" :style="pBarSize">
        <div class="custom-progress-bar"
             role="progressbar"
             aria-valuenow=""
             aria-valuemin="0"
             aria-valuemax="100"
             :style="progress">
        </div>
      </div>
	   <div v-for="(step,index) in steps" class="step-section" :key="index">
      <div class="progress-bar-stepper" >
        <div class="progress-step" :id="`step-${index}`" :class="{'inactive-step':isNaN(activeStepNo) || activeStepNo<index,'completed-step':!isNaN(activeStepNo) && activeStepNo>index,'active-step':!isNaN(activeStepNo) && activeStepNo===index}"></div>
        </div>
        <div class="step-value">{{index + 1}}. {{step}}</div> 
     </div>	
	</div>
</template>
<script type="text/javascript">
  export default {
    props:{
      steps:{
        type:Array,
        required:true
      },
      activeStepNo:{
        type:Number,
        required:false
      }
    },
    data(){
      return {
        pBarSize:'',
        progress:''
      }
    },
    computed:{
      isGifLoading(){
        return this.$store.getters.isGifLoading;
      }
    },
    watch:{
      isGifLoading(newValue){
        if(!newValue){
          this.calculateBarPosition();
        }
      }
    },
    mounted(){
      this.$nextTick(() => {
        this.calculateBarPosition();
     });
     setTimeout(()=>this.calculateBarPosition(),500);
     window.addEventListener('resize', this.calculateBarPosition);
    },
    methods:{
      calculateBarPosition() {
        let docEl = document.documentElement;
        let bar = document.getElementById('nr-progress-bar');
        bar = bar.getBoundingClientRect();
        const first = document.getElementById('step-0');
        let rect = first.getBoundingClientRect();
        const last = document.getElementById('step-'+ (this.steps.length - 1));
        let lastRect = last.getBoundingClientRect();
        const offset = rect.left + (window.pageXOffset || docEl.scrollLeft || 0) - bar.left;
        const lastOffset = lastRect.left - (window.pageXOffset || docEl.scrollLeft || 0) - bar.left;
        this.pBarSize = `left: ${offset}px; width: ${lastOffset - offset}px;`;
        this.calculateProgress();
      },
      calculateProgress(){
        if (this.activeStepNo > this.steps.length) {
          this.progress = `width: 100%`;
        }
        const first = document.getElementById('step-0');
        const current = document.getElementById(`step-${this.activeStepNo}`);
        if (first && current) {
          const delta = current.getBoundingClientRect().left -first.getBoundingClientRect().left;
          this.progress = `width: ${delta}px;`;
        }
      
      }
    },
    beforeDestroy(){
      window.removeEventListener('resize', this.calculateBarPosition);
    }
  }
</script>
<style type="text/css">
  .progress-bar-wrapper{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 9px;
    padding-bottom: 12px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
  }

  .progress-bar-wrapper .step-action{
    text-align: center;
    color: #000000;
    font-size: 12px;
    line-height: 15px;
  }

  .progress-bar-stepper{
    height: 16px;
    display: flex;
    align-items: center;
  }

  .progress-bar-wrapper .progress-step{
    height: 10px;
    width: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    z-index: 2;
    position: relative;
    /* background: #D3EBFC; */
    background: rgba(224, 224, 224, 1);

  }

  .progress-bar-wrapper .progress-step.completed-step{
    background: url('../assets/images/progress-bar-complete-step.svg');
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
  }

  .progress-bar-wrapper .progress-step.active-step{
    /* background: #0075C4; */
    background: rgba(4, 153, 183, 1);

  }

  .progress-bar-wrapper .step-value{
    display: inline-block;
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
    color: #000000;
  }

  .custom-progress {
    position: absolute;
    top: 1rem;
    height: 3px;
    margin: 0;
    z-index: 1;
    background: rgba(224, 224, 224, 1);
    border-radius: 10px;
    box-shadow: none;
  }
  .custom-progress .custom-progress-bar {
      background-color: #1EB4D2;
      height: 3px;
  }

  @media (max-width:991px){
    .progress-bar-wrapper .step-value{
      font-size: 8px;
    }
    .progress-bar-wrapper{
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .custom-progress{
      top: 13px;
    }
  }

  @media (max-width:375px){
    .progress-bar-wrapper .step-value{
      font-size: 6px;
    }
  }

</style>